import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/modus-react-bootstrap/modus-react-bootstrap/docs/src/layouts/MainLayout.tsx";
import { graphql } from "gatsby";
import { ModalsBasic, ModalsLiveExample, ModalsOptionalSizes } from "../../examples/components/Modals";
export const query = graphql`
  query ModalQuery {
    Modal: componentMetadata(displayName: { eq: "Modal" }) {
      ...ComponentApi_metadata
    }
    ModalDialog: componentMetadata(displayName: { eq: "ModalDialog" }) {
      ...ComponentApi_metadata
    }
    ModalHeader: componentMetadata(displayName: { eq: "ModalHeader" }) {
      ...ComponentApi_metadata
    }
    ModalTitle: componentMetadata(displayName: { eq: "ModalTitle" }) {
      ...ComponentApi_metadata
    }
    ModalBody: componentMetadata(displayName: { eq: "ModalBody" }) {
      ...ComponentApi_metadata
    }
    ModalFooter: componentMetadata(displayName: { eq: "ModalFooter" }) {
      ...ComponentApi_metadata
    }
  }
`;
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Overview = makeShortcode("Overview");
const CodeBlock = makeShortcode("CodeBlock");
const LinkedHeading = makeShortcode("LinkedHeading");
const ComponentApi = makeShortcode("ComponentApi");
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Overview mdxType="Overview">
  <p>
    Modals are positioned over everything else in the document and remove scroll
    from the <code>&lt;body&gt;</code> so that modal content scrolls instead.
    Modals are <em>unmounted</em> when closed.
  </p>
  <p>
    Bootstrap only supports <strong>one</strong> modal window at a time. Nested
    modals aren’t supported, but if you really need them the underlying{" "}
    <code>react-overlays</code> can support them if you're willing.
  </p>
  <p>
    Modal's "trap" focus in them, ensuring the keyboard navigation cycles
    through the modal, and not the rest of the page. Unlike vanilla Bootstrap,{" "}
    <code>autoFocus</code> works in Modals because React handles the
    implementation.
  </p>
    </Overview>
    <CodeBlock title="Basic Modal" code={ModalsBasic} mdxType="CodeBlock">
  A modal with header, body, and set of actions in the footer. Use{" "}
  <code>&lt;Modal/&gt;</code> in combination with other components to show or
  hide your Modal. The <code>&lt;Modal/&gt;</code> Component comes with a few
  convenient "sub components": <code>&lt;Modal.Header/&gt;</code>,{" "}
  <code>&lt;Modal.Title/&gt;</code>, <code>&lt;Modal.Body/&gt;</code>, and{" "}
  <code>&lt;Modal.Footer/&gt;</code>, which you can use to build the Modal
  content.
    </CodeBlock>
    <CodeBlock title="Live Example" noInline={true} code={ModalsLiveExample} mdxType="CodeBlock">
  Use <code>show</code> prop on <code>&lt;Modal&gt;</code> to control its
  visibility and <code>useState</code> to set the visibility state on click of{" "}
  <code>&lt;Button&gt;</code>. You can vertically center a modal by passing the
  "centered" prop.
    </CodeBlock>
    <CodeBlock title="Optional Sizes" noInline={true} code={ModalsOptionalSizes} mdxType="CodeBlock">
  You can specify a bootstrap large or small modal by using the "size"
  prop.These sizes kick in at certain breakpoints to avoid horizontal scrollbars
  on narrower viewports.
  <table className="table bg-striped bg-white border">
    <thead>
      <tr>
        <th>Size</th>
        <th>Class</th>
        <th>Modal max-width</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>Small</td>
        <td>
          <code>.modal-sm</code>
        </td>
        <td>
          <code>300px</code>
        </td>
      </tr>
      <tr>
        <td>Default</td>
        <td className="text-muted">None</td>
        <td>
          <code>500px</code>
        </td>
      </tr>
      <tr>
        <td>Large</td>
        <td>
          <code>.modal-lg</code>
        </td>
        <td>
          <code>800px</code>
        </td>
      </tr>
      <tr>
        <td>Extra large</td>
        <td>
          <code>.modal-xl</code>
        </td>
        <td>
          <code>1140px</code>
        </td>
      </tr>
    </tbody>
  </table>
    </CodeBlock>
    <LinkedHeading h="2" className="h1" id="modals-api" mdxType="LinkedHeading">
  API
    </LinkedHeading>
    <ComponentApi metadata={props.data.Modal} mdxType="ComponentApi" />
    <ComponentApi metadata={props.data.ModalDialog} mdxType="ComponentApi" />
    <ComponentApi metadata={props.data.ModalHeader} mdxType="ComponentApi" />
    <ComponentApi metadata={props.data.ModalTitle} mdxType="ComponentApi" />
    <ComponentApi metadata={props.data.ModalBody} mdxType="ComponentApi" />
    <ComponentApi metadata={props.data.ModalFooter} mdxType="ComponentApi" />


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      